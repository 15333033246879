import {
  Box,
  Button,
  Heading,
  Link,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import React from "react";
import { Head } from "../../components/Head";
import Layout from "../../components/Layout";
import Section from "../../components/Section";
import { SharedHero } from "../../components/shared/SharedHero";

type IProps = {};

const PS2Page: React.FC<IProps> = () => {
  return (
    <Layout>
      <Head title="PSD2, la Direttiva Europea sui servizi di pagamento" />

      <SharedHero />

      <Section bg={"utils.white"}>
        <Box>
          <Stack spacing={4} direction={"column"} textAlign={"justify"}>
            <Heading
              as={"h1"}
              fontSize={["24px", "32px"]}
              fontWeight={"400"}
              textAlign={"left"}
              fontFamily={"body"}
              color={"accent.01"}
            >
              Cos'è la direttiva europea sui servizi di pagamento (PSD2)?
            </Heading>
            <Text>
              Il 13 gennaio 2018 è entrata in vigore la nuova Direttiva Europea
              sui servizi di pagamento, conosciuta anche con l’acronimo di PSD2.
            </Text>
            <Text>
              La PSD2 è nata per promuovere l’innovazione e lo sviluppo dei
              digital payment, rafforzare gli standard di sicurezza e di
              protezione dei consumatori nei servizi di pagamento e aumentare i
              livelli di concorrenza tra gli operatori del settore.
            </Text>
            <Text>
              In sintesi, i principi fondanti della PSD2 sono i seguenti:
            </Text>
            <UnorderedList pl={5}>
              <ListItem>
                maggiore trasparenza nei servizi di pagamento;
              </ListItem>
              <ListItem>
                competizione e innovazione nei servizi di pagamento online;
              </ListItem>
              <ListItem>
                aumento della sicurezza nelle transazioni mediante
                l’introduzione di requisiti più stringenti per i prestatori di
                servizi di pagamento.
              </ListItem>
            </UnorderedList>
            <Heading
              as={"h4"}
              fontSize={["24px", "32px"]}
              fontWeight={"400"}
              fontFamily={"body"}
              color={"accent.01"}
              textAlign={"left"}
            >
              Cos’è la Strong Customer Authentication (Autenticazione Forte)?
            </Heading>
            <Text>
              Dal 14 settembre 2019 gli Intermediari finanziari dovranno
              adeguare le proprie misure di autenticazione rafforzando la
              sicurezza dei pagamenti online nell'Unione Europea sia per
              l’accesso alla propria Area Riservata, sia per la disposizione
              delle operazioni di pagamento, tramite l’utilizzo di due o più
              strumenti di autenticazione classificati come:
            </Text>
            <UnorderedList pl={5}>
              <ListItem>
                “conoscenza” qualcosa che solo l’utente conosce;
              </ListItem>
              <ListItem>“possesso” qualcosa che solo l’utente ha;</ListItem>
              <ListItem>“inerenza” qualcosa che solo l’utente è.</ListItem>
            </UnorderedList>
            <Text>L'autenticazione forte è richiesta quando un cliente:</Text>
            <UnorderedList pl={5}>
              <ListItem>accede alla propria area riservata on-line;</ListItem>
              <ListItem>
                dispone un'operazione di pagamento elettronico;
              </ListItem>
              <ListItem>
                esegue qualsiasi azione, tramite un canale a distanza, che può
                comportare un rischio di frode nei pagamenti o altri abusi.
              </ListItem>
            </UnorderedList>
            <Text>
              Per scoprire gli aggiornamenti e le novità ti invitiamo a
              consultare la seguente documentazione informativa:
            </Text>
            <UnorderedList pl={5}>
              <ListItem>
                <Link
                  href="/documents/psd2/2021_09 Manuale Area Riservata_Clienti_HCBE_V.4.pdf"
                  color="accent.01"
                >
                  Area clienti prestiti (PDF)
                </Link>
              </ListItem>
              <ListItem>
                <Button variant={"link"} as={Link} color="accent.01" disabled>
                  Area dealer
                </Button>
              </ListItem>
            </UnorderedList>
            <Heading
              as={"h4"}
              fontSize={["24px", "32px"]}
              fontWeight={"400"}
              fontFamily={"body"}
              color={"accent.01"}
              textAlign={"left"}
            >
              Hai bisogno di aiuto?
            </Heading>
            <Text>
              Potrai chiamarci e parlare con un operatore dal lunedì al venerdì
              dalle 09:00 alle 19:00 al seguente riferimento:{" "}
              <Link
                color={"accent.01"}
                fontWeight={"bold"}
                href={"tel:848800259"}
              >
                848.800259
              </Link>{" "}
              o dall’estero al numero{" "}
              <Link
                color={"accent.01"}
                fontWeight={"bold"}
                href={"tel:0116318911"}
              >
                0116318911
              </Link>
            </Text>
          </Stack>
        </Box>
      </Section>
    </Layout>
  );
};

export default PS2Page;
